import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILED,
  FETCH_USER_PROFILE_START,
  CLEAR_ACTIVE_NAVIGATION,
  SET_ACTIVE_NAVIGATION,
  CLEAR_USER_PROFILE,
  SET_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION,
  CLEAR_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION,
  VERIFY_PHONE_NUMBER_FALSE,
  VERIFY_PHONE_NUMBER_TRUE,
} from "../constants";
import moment from "moment";

export const userProfileReducer = (
  state = { isFetchingProfile: null, profile: null, lastTimeFetched: null },
  { type, payload }
) => {
  switch (type) {
    case FETCH_USER_PROFILE_START:
      return {
        ...state,
        isFetchingProfile: true,
        profile: state.profile,
      };
    case FETCH_USER_PROFILE_FAILED:
      return {
        ...state,
        isFetchingProfile: false,
        profile: state.profile,
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        lastTimeFetched: moment(),
        isFetchingProfile: false,
        profile: payload,
      };
    case CLEAR_USER_PROFILE:
      return {
        lastTimeFetched: null,
        isFetchingProfile: null,
        profile: null,
      };
    default:
      return state;
  }
};

export const globalNavigationReducer = (
  state = {
    activeNavigationKey: "dashboard",
    redirectRouteAfterPhoneNumberVerification: window.localStorage.getItem(
      "redirect_route_after_phone_verification"
    ),
  },
  { type, payload }
) => {
  switch (type) {
    case CLEAR_ACTIVE_NAVIGATION:
      return {
        ...state,
        activeNavigationKey: null,
      };
    case SET_ACTIVE_NAVIGATION:
      return {
        ...state,
        activeNavigationKey: payload,
      };
    case SET_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION:
      window.localStorage.setItem(
        "redirect_route_after_phone_verification",
        payload
      );
      return {
        ...state,
        redirectRouteAfterPhoneNumberVerification: payload,
      };
    case CLEAR_REDIRECT_ROUTE_AFTER_PHONE_VERIFICATION:
      window.localStorage.setItem(
        "redirect_route_after_phone_verification",
        null
      );
      return {
        ...state,
        redirectRouteAfterPhoneNumberVerification: null,
      };
    default:
      return state;
  }
};

export const phoneNumberVerificationReducer = (
  state = {
    should_verify_phone_number: { val: false },
  },
  { type }
) => {
  switch (type) {
    case VERIFY_PHONE_NUMBER_FALSE:
      return {
        should_verify_phone_number: { val: false },
      };
    case VERIFY_PHONE_NUMBER_TRUE:
      return {
        should_verify_phone_number: { val: true },
      };

    default:
      return state;
  }
};
