import React, { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "./../../../../Global/Button";
import { ModalComponent } from "./../../../../Global/graphical/Modal";
import { Spinner } from "./../../../../Global/graphical/Spinner";
import { Preference } from "./components/Preferences";
import { STUDENT_ADMISSION_STATUS_TYPES } from "../../../../../config/store/constants";
import { axiosServerInstance } from "../../../../../config/api/axios";
import { notifySuccess } from "../../../../../helper/notifications/notifySuccess";
import { notifyFailure } from "../../../../../helper/notifications/notifyFailure";
import { useParams } from "react-router-dom";
import { notifyApiErrorMessage } from "../../../../../helper/notifications/notifyApiErrorMessage";
import { GlobalUserProfileContext } from "../../../../../App";
import { SizedBox } from "../../../../Global/SizedBox";
import { RefreshAuthUserAffiliatedObjectsButtonComponent } from "../../../../Global/utils/RefreshAuthUserAffiliatedObjectsButton";

const StyledComponent = styled.div`
  .actionButtonWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }

  .ReactModalPortal {
    .ReactModal__Content {
      @media (max-width: 500px) {
        padding: 2rem 0.5rem !important;
      }
    }
  }
`;

export const PROStudentPreferencesContext = createContext({});
export const PreferencesContainer = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const { id: studentID } = useParams();
  const [studentCurrentStatusData, setStudentCurrentStatusData] =
    useState(null);
  const { profile } = useContext(GlobalUserProfileContext);
  const [isFetchingSubjectTeachersData, setIsFetchingSubjectTeachersData] =
    useState(false);
  const [subjectTeachersListData, setSubjectTeacherListData] = useState([]);
  const [canConfirmSubjects, setCanConfirmSubjects] = useState(false);

  const getSubjectTeacherData = (subject_id, teacher_id, class_id) => {
    return subjectTeachersListData.find(
      (item) =>
        item.subject_id.toString() === subject_id.toString() &&
        item.teacher_id.toString() === teacher_id.toString() &&
        item.class_id.toString() === class_id.toString() &&
        item.campus_id.toString() ===
          profile?.pro_account_data?.campus_id?.toString()
    );
  };

  const getAvailableSeat = async (subject_id, teacher_id, class_id) => {
    if (subject_id && teacher_id && class_id) {
      const subjectTeacherData = getSubjectTeacherData(
        subject_id,
        teacher_id,
        class_id
      );
      if (subjectTeacherData) {
        const endpoint = `/shared/campuses/subjects_teachers/${subjectTeacherData.id}/available_seats`;
        return await axiosServerInstance()
          .get(endpoint)
          .then((response) => {
            return response?.data?.available_seats;
          })
          .catch((err) => {
            notifyFailure(
              "Error in fetching available seats for the selected subject and teacher combination"
            );
          });
      }
    }
  };
  const fetchSubjectTeachersDetails = async () => {
    const endpoint = `/shared/campuses/subjects_teachers/raw_list?campus_id=${profile?.pro_account_data?.campus_id}`;
    setIsFetchingSubjectTeachersData(true);
    await axiosServerInstance()
      .get(endpoint)
      .then((response) => {
        setSubjectTeacherListData(response?.data?.results);
      })
      .catch((err) => {
        notifyFailure("Error in fetching subjects details");
      });
    setIsFetchingSubjectTeachersData(false);
  };

  const fetchStudentCurrentStatus = async () => {
    const endpoint = `/pro/students/${studentID}/admission/status/current`;
    return await axiosServerInstance()
      .get(endpoint)
      .then((response) => {
        setStudentCurrentStatusData(response.data);
      })
      .catch((err) => {
        notifyFailure("Error in fetching student admission information");
      });
  };

  const pushAdmissionDataToServer = async () => {
    const endpoint = `/pro/students/action/${studentID}/push_non_existing_student_data_to_db/`;
    await axiosServerInstance()
      .post(endpoint)
      .then((response) => {
        notifySuccess("Operation Successful");
      })
      .catch((err) => {
        notifyFailure("Error in confirming subjects");
      });
  };

  const handleOnClickProceed = async () => {
    setShowSpinner(true);
    await pushAdmissionDataToServer();
    setShowSpinner(false);
  };
  const handleOnClickUpdateButton = async (e) => {
    e.preventDefault();
  };
  const hanldeEditClick = async (id) => {};

  useEffect(() => {
    fetchStudentCurrentStatus();
  }, [studentID]);

  useEffect(() => {
    fetchSubjectTeachersDetails();
  }, []);
  return (
    <>
      <SizedBox height={1} />
      <RefreshAuthUserAffiliatedObjectsButtonComponent
        callBackOnComplete={fetchSubjectTeachersDetails}
      />
      <StyledComponent>
        {!isFetchingSubjectTeachersData ? (
          <>
            <div>
              <PROStudentPreferencesContext.Provider
                value={{
                  subjectTeachersListData,
                  getAvailableSeat,
                  setCanConfirmSubjects,
                }}
              >
                <Preference
                  callbackAction={handleOnClickUpdateButton}
                  callbackEditCilck={hanldeEditClick}
                />
              </PROStudentPreferencesContext.Provider>
            </div>
            {![
              STUDENT_ADMISSION_STATUS_TYPES.CANCELLED,
              STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED,
              STUDENT_ADMISSION_STATUS_TYPES.APPLIED,
            ].includes(studentCurrentStatusData?.admission_status) && (
              <div className='actionButtonWrapper'>
                {showSpinner ? (
                  <Spinner size={1.5} />
                ) : (
                  <div>
                    <Button
                      fontSize={1}
                      borderRadius={2.4}
                      paddingTopBottom={0.6}
                      paddingLeftRight={3}
                      disabled={!canConfirmSubjects}
                      onClick={handleOnClickProceed}
                    >
                      PUSH DATA INTO BEAMS
                    </Button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div className={"spinnerWrapper"}>
            <SizedBox height={1} />
            <Spinner size={2} />
            <SizedBox height={1} />
          </div>
        )}
      </StyledComponent>
    </>
  );
};
