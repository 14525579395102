import styled from "styled-components";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { useContext, useEffect, useRef, useState } from "react";
import { SubjectConfirmationContext } from "../Container";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../../helper/notifications/notifyApiErrorMessage";
import { STUDENT_ADMISSION_STATUS_TYPES } from "../../../../config/store/constants";
import { useNavigate } from "react-router-dom";
import { axiosServerInstance } from "../../../../config/api/axios";
import { formatQueryDictToQueryParams } from "../../../../helper/formatters/formatDictToParams";
import { Spinner } from "../../../Global/graphical/Spinner";
import search from "../../../../static/images/table/Path 123.svg";
import { GlobalUserProfileContext } from "../../../../App";
import { SizedBox } from "../../../Global/SizedBox";

const StyledComponent = styled.div`
  .searchActions {
    justify-content: flex-end;
  }
  .tableOuter {
    max-height: 560px;
    overflow: auto;
    ${"" /* overflow: scroll; */}
    ${"" /* overflow-x: auto; */}
  }
  .infoWrapper {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const SubjectConfirmationListComponents = () => {
  const [showEditStudentModal, setShowEditStudentModal] = useState(false);
  const [tableBodyData, setTableBodyData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const endpointQueryParam = useRef({});

  const { students } = useContext(SubjectConfirmationContext);
  // const { setTargetPage } = useContext(GlobalUserProfileContext);

  const navigate = useNavigate();
  const headerData = [
    "ID",
    "BSS ID",
    "Name",
    "PHONE NO.",
    "REGION",
    "City",
    "Campus",
    "INTERVIEW STATUS",
    "",
  ];
  const handleCancelEditStudent = () => {
    setShowEditStudentModal(false);
  };

  const handleSaveEditStudent = (data) => {};

  // const onClickEdit = (id) => {
  //     setShowSpinner(true);
  //     // setTargetPage("PREFERENCES");
  //     navigate(`/studentDetail`, { replace: true });
  //     setShowSpinner(false);
  // };

  const handleSelectChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleOnClickAddStudent = (id) => {
    setShowSpinner(true);

    navigate(`/addStudent`, { replace: true });
    setShowSpinner(false);
  };

  const onClickEdit = async (id) => {
    setShowSpinner(true);
    // setTargetPage("STATUS DETAILS");
    navigate(`/pro/students/${id}/`, { replace: true });
    setShowSpinner(false);
  };

  const handleSearchInputChange = (e) => {
    let query = e.target.value;
    setSearchParam(e.target.value);
    handleSearch(query);
  };

  const handleSearch = (query) => {
    if (query) {
      endpointQueryParam.current = {
        ...endpointQueryParam,
        search: query,
      };
    } else {
      const tempData = {
        ...endpointQueryParam.current,
      };
      delete tempData["search"];
      endpointQueryParam.current = tempData;
    }
    fetchAndParseDataFromAPI();
  };

  const [data, setData] = useState([]);
  const fetchAndParseDataFromAPI = async () => {
    const endpoint = `/pro/students${formatQueryDictToQueryParams(
      endpointQueryParam.current,
      "?"
    )}`;
    setShowSpinner(true);
    axiosServerInstance()
      .get(endpoint)
      .then((response) => {
        setData(
          response.data?.results?.filter(
            (info) =>
              info?.account_data?.admission_status?.admission_status ===
              STUDENT_ADMISSION_STATUS_TYPES.INTERVIEWED
          )
        );
        setShowSpinner(false);
      })
      .catch((err) => {
        notifyFailure("Error in fetching students");
        setShowSpinner(false);
        notifyApiErrorMessage(err);
      });
  };

  useEffect(() => {
    fetchAndParseDataFromAPI();
  }, []);

  useEffect(() => {
    if (data) {
      setTableBodyData(
        data.map((item, index) => {
          return {
            id: item.id,
            data: [
              {
                type: "text",
                value: index + 1,
              },
              {
                type: "text",
                value: item?.id,
              },
              {
                type: "text",
                value: item?.full_name,
              },
              {
                type: "text",
                value: item?.phone_number,
              },
              {
                type: "text",
                value: "", // No REGION from API yet!
              },

              {
                type: "text",
                value: item?.city_campus[0]?.city_name,
              },
              {
                type: "text",
                value: item?.city_campus[0]?.campus_name,
              },
              {
                type:
                  item?.account_data?.admission_status?.admission_status &&
                  "badge-custom",
                value: item?.account_data?.admission_status?.admission_status,
              },
              {
                type: "edit",
                value: onClickEdit,
              },
            ],
          };
        })
      );
    }
  }, [data]);

  return (
    <StyledComponent>
      <div className='searchActions'>
        <div className='searchOuter'>
          <input
            type='search'
            placeholder='Search student by name, city...'
            value={searchParam}
            onChange={handleSearchInputChange}
          />
          <img
            src={search}
            alt='search icon'
            className='searchIcon'
            onClick={(searchParam) => handleSearch}
          />
        </div>
      </div>
      <SizedBox height={1} />
      {!showSpinner ? (
        <TableComponent
          headerData={headerData}
          bodyData={tableBodyData}
          actionEdit={{
            edit: onClickEdit,
          }}
        />
      ) : (
        <div className='infoWrapper'>
          <Spinner />
        </div>
      )}
    </StyledComponent>
  );
};
