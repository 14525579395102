import React, { useState } from "react";
import { Button } from "./../../../../Global/Button";
import { FormFieldsWrapper } from "./../../../../Global/graphical/data_input/FormFieldsWrapper";
import { Spinner } from "./../../../../Global/graphical/Spinner";
import styled from "styled-components";
import { notifyFailure } from "./../../../../../helper/notifications/notifyFailure";

const StyledComponent = styled.div`
  margin: 1rem 1.5rem;
  .formTitle {
    font-size: 2rem;
    margin-left: 1rem;
    color: var(--custom-btn-bg);
    margin-bottom: 2rem;
  }
  .ReactModal__Content {
    @media (max-width: 580px) {
      padding: 2rem 0.5rem;
    }
  }
  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media screen and (max-width: 45rem) {
      grid-template-columns: repeat(1, 1fr);
    }
    .formfieldInner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: end;
      gap: 1rem;
      @media (max-width: 1220px) {
        flex-wrap: wrap;
      }
      button {
        height: 40px;
      }
      .inputOuter {
        width: 32%;
      }

      .inputOuter.w-38 {
        width: 100%;
        min-width: 430px;
        @media (max-width: 840px) {
          min-width: 250px;
        }
        @media (max-width: 45rem) {
          width: 100%;
        }
      }
      textarea {
        min-width: 40rem;
        display: flex;
        height: 9rem;
        text-indent: 10px;
        background: var(--custom-input-bg);
        border-radius: 10px;
        outline: none;
        background-image: none;
        overflow: hidden;
        border: 1px solid var(--custom-input-border);
        @media (max-width: 800px) {
          min-width: 100%;
        }
      }
    }
  }
  select {
    width: 100%;
    height: 3rem;
    text-indent: 10px;
    background: var(--custom-input-bg);
    border-radius: 25px;
    outline: none;
    background-image: none;
    overflow: hidden;
    border: 1px solid var(--custom-input-border);
    option {
      border-radius: 25px;
      width: 32%;
      background: var(--custom-input-bg);
    }
  }

  .formFieldWrapperSubject {
    align-items: flex-start;
    .helperText {
      font-size: 12px;
      margin-left: 1rem;
      line-height: 1.5rem;
    }
  }
  .actionButtonWrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
  }
`;

export const CancleProcess = ({ callbackCancel, callbackProceed }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectReasons, setSelectReasons] = useState("");
  const [selectReasonsOther, setSelectReasonsOther] = useState("");

  const handleOnClickProceed = (e) => {
    e.preventDefault();
    ValidateData();
  };
  const ValidateData = () => {
    if (!selectReasons) {
      notifyFailure("Please select a reason for the admission cancellation");
    } else if (
      selectReasons &&
      selectReasons === "other" &&
      !selectReasonsOther
    ) {
      notifyFailure("Kindly type in the reason for the admission cancellation");
    } else {
      callbackProceed(
        selectReasons === "other" ? selectReasonsOther : selectReasons
      );
    }
  };

  const handleOnClickBack = () => {
    setSelectReasons("");
  };

  const ReasonsOptions = [
    "Select Any Reason",
    "Self withdrawal",
    "Matriculation",
    "other",
  ];
  return (
    <StyledComponent>
      <h2 className='formTitle'>Reasons for cancellation:</h2>
      <FormFieldsWrapper className='formFieldWrapper' autocomplete='off'>
        <div className='formfieldInner'>
          <div className='inputOuter w-38'>
            <label>Reasons : </label>
            {selectReasons && selectReasons === "other" ? (
              <textarea
                value={selectReasonsOther}
                onChange={(e) => setSelectReasonsOther(e.target.value)}
              />
            ) : (
              <select
                placeholder='Self withdrawal'
                value={selectReasons}
                onChange={(e) => setSelectReasons(e.target.value)}
              >
                {ReasonsOptions.map((config, i) => (
                  <option value={config}>{config}</option>
                ))}
              </select>
            )}
          </div>
          {selectReasons && selectReasons === "other" && (
            <div className='actionButtonWrapper'>
              {showSpinner ? (
                <Spinner size={1.5} />
              ) : (
                <Button
                  fontSize={0.9}
                  maxWidth={10}
                  borderRadius={2.4}
                  paddingTopBottom={0.5}
                  paddingLeftRight={3.7}
                  onClick={handleOnClickBack}
                >
                  Back
                </Button>
              )}
            </div>
          )}
          <div className='actionButtonWrapper'>
            {showSpinner ? (
              <Spinner size={1.5} />
            ) : (
              <Button
                fontSize={0.9}
                maxWidth={10}
                borderRadius={2.4}
                paddingTopBottom={0.5}
                paddingLeftRight={3.7}
                onClick={handleOnClickProceed}
              >
                ADD
              </Button>
            )}
          </div>
        </div>
      </FormFieldsWrapper>
    </StyledComponent>
  );
};
