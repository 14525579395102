import React, { createContext } from "react";
import styled from "styled-components";
import { Button } from "../../Global/Button";
import { MainWrapper } from "../../Global/MainWrapper";
import { DetailTable } from "./components/DetailTable";
import { axiosServerInstance } from "../../../config/api/axios";
import FilterByBatch from "../../Global/filters/FilterByBatch";
import { useState } from "react";

export const StudentsContext = createContext({});
const StyledComponent = styled.div`
  width: 100%;

  .component {
    margin-top: 3rem;
  }

  .formFieldWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .formfieldInner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      gap: 1rem;

      .inputOuter.w-32 {
        width: 39.2%;
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }

      .inputOuter.w-22 {
        width: 25%;
        @media screen and (max-width: 1545px) {
          width: 30%;
        }
        @media screen and (max-width: 45rem) {
          width: 100%;
        }
      }
    }

    .actionButtonWrapper {
      align-self: end;
      justify-self: end;
    }
  }

  .ActionButtons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  .actionButtonSave {
    display: flex;
    justify-content: flex-end;
    margin: 0rem 0 0rem;
  }

  .table {
    margin: 1rem 0;

    .tableOuter {
      padding: 1rem 0px;
    }
  }

  .table > div > div {
    box-shadow: none;
    padding: 1rem 0px;
  }

  .title {
    margin-bottom: 3rem;
  }
`;

export const ScholarshipContainer = () => {
  const [batchFilter, setBatchFilter] = useState({ batch: "" });
  const handleOnClickDownload = () => {
    axiosServerInstance()
      .get(
        `/shared/campuses/my_campus/scholarship/export_record/?batch=${batchFilter?.batch}`,
        { responseType: "blob" }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PRO Scholarship Export.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  return (
    <MainWrapper pageTitle={"SCHOLARSHIP"}>
      <StyledComponent>
        <>
          <div className='searchActions'>
            <div className='selectOuter'>
              <FilterByBatch setBatchFilter={setBatchFilter} />
            </div>
            <div className='actionButtonSave'>
              <Button
                fontSize={1}
                borderRadius={2.4}
                maxWidth={10}
                paddingTopBottom={0.6}
                paddingLeftRight={1.5}
                onClick={handleOnClickDownload}
              >
                DOWNLOAD
              </Button>
            </div>
          </div>

          <div className='component'>
            <DetailTable batchFilter={batchFilter} />
          </div>
        </>
      </StyledComponent>
    </MainWrapper>
  );
};
