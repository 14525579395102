import { createContext, useEffect, useState } from "react";
import { MainWrapper } from "../../Global/MainWrapper";
import { SubjectConfirmationListComponents } from "./components/SubjectConfirmationList";
import { StudentPROData } from "../../../config/DataList/studentsPROData/StudentsPROData";

export const SubjectConfirmationContext = createContext({});

export const SubjectConfirmationPROContainer = () => {
  // const [students, setStudents] = useState([]);
  // useEffect(() => {
  //   setStudents(StudentPROData);
  // }, []);
  return (
    <MainWrapper pageTitle={"Selection"}>
      {/* <SubjectConfirmationContext.Provider
        value={{
          students,
        }}
      > */}
      <SubjectConfirmationListComponents />
      {/* </SubjectConfirmationContext.Provider> */}
    </MainWrapper>
  );
};
