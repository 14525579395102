import { Route, Routes } from "react-router-dom";
import { ProtectedRouteComponent } from "../../components/Global/ProtectedRouteComponent";
import { authPagesConditions } from "../../conditions";
import { AnalyticsContainer } from "../../components/regional_head/analytics/Container";
import { LogsContainer } from "../../components/regional_head/logs/Container";
import { FacultyContainer } from "../../components/regional_head/faculity/Container";
import { ScholarshipContainer } from "../../components/regional_head/scholarship/Container";
import { ViewSubject } from "../../components/regional_head/scholarship/viewSubject/ViewSubject";
import { ViewStudentSubject } from "../../components/regional_head/students/viewSubject/ViewSubject";
import { ViewPersonalInfo } from "../../components/regional_head/students/viewPersonalInfo/ViewPersonalInfo";
import { StudentsRegionalHeadContainer } from "../../components/regional_head/students/Container";
import { ViewCampus } from "../../components/regional_head/faculity/viewCampus/ViewCampus";
import { ViewSubjectDetail } from "../../components/regional_head/faculity/viewSubjectDetail/ViewSubjectDetail";
import { FeeManagementContainer } from "../../components/regional_head/feeManagement/Container";

export const RegionalHeadRoutes = () => {
  return (
    <Routes>
      <Route
        path='/regionalhead/analytics'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<AnalyticsContainer />}
          />
        }
      />

      <Route
        path='/regionalhead/logs'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<LogsContainer />}
          />
        }
      />
      <Route
        path='/regionalhead/feemanagement'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<FeeManagementContainer />}
          />
        }
      />
      <Route
        path='/regionalhead/faculty'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<FacultyContainer />}
          />
        }
      />
      <Route
        path='/regionalhead/faculty/campus/:id'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<ViewCampus />}
          />
        }
      />
      <Route
        path='/regionalhead/faculty/subject/:id'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<ViewSubjectDetail />}
          />
        }
      />
      <Route
        path='/regionalhead/students'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<StudentsRegionalHeadContainer />}
          />
        }
      />
      <Route
        path='/regionalhead/students/:id/subject'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<ViewStudentSubject />}
          />
        }
      />
      <Route
        path='/regionalhead/students/:id/personalinfo'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<ViewPersonalInfo />}
          />
        }
      />
      <Route
        path='/regionalhead/scholarship'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<ScholarshipContainer />}
          />
        }
      />
      <Route
        path='/regionalhead/scholarship/:id'
        exact={true}
        element={
          <ProtectedRouteComponent
            conditions={authPagesConditions}
            elementToRenderOnPass={<ViewSubject />}
          />
        }
      />
    </Routes>
  );
};
