import { Button } from "../../Global/Button";
import { SizedBox } from "../../Global/SizedBox";
import { AuthButtonContainer } from "../components/AuthButtonContainer";
import { FormComponent } from "../components/FormElement";
import { FormFooterPrompt } from "../components/FormFooterPrompt";
import { InputComponent } from "../components/InputELement";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../Global/graphical/Spinner";
import { axiosServerInstance } from "../../../config/api/axios";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../config/store/actions";
import { notifyApiErrorMessage } from "../../../helper/notifications/notifyApiErrorMessage";
import { handleOnInputChange } from "../../../helper/form_inputs/handleOnInputChange";
import { notifyFailure } from "../../../helper/notifications/notifyFailure";

export const LoginNonBssForm = () => {
  const [data, setData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateFields = () => {
    let state = true;
    const fields = ["email", "password"];
    for (let field of fields) {
      if (!data[field === "email" ? "login" : field]) {
        notifyFailure(`${field} is required`);
        state = false;
      }
    }
    return state;
  };

  const handleProceedLogin = async () => {
    if (!validateFields()) {
      return;
    }
    setShowSpinner(true);
    axiosServerInstance()
      .post("/auth/login/", data)
      .then((response) => {
        const { refresh, access } = response.data;
        window.localStorage.setItem("access_token", access);
        window.localStorage.setItem("refresh_token", refresh);
        dispatch(fetchUserProfile);
        navigate("/", { replace: true });
      })
      .catch((err) => {
        setShowSpinner(false);
        notifyApiErrorMessage(err);
      });
  };

  return (
    <FormComponent>
      <label>Your Email</label>
      <InputComponent
        placeholder={""}
        type='email'
        height={2.5}
        onChange={(e) => handleOnInputChange(e, "login", setData, data)}
      />
      <SizedBox height={1.5} />
      <label>Password</label>
      <InputComponent
        placeholder={""}
        type='password'
        height={2.5}
        onChange={(e) => handleOnInputChange(e, "password", setData, data)}
      />
      <SizedBox height={1.5} />

      <AuthButtonContainer>
        {!showSpinner ? (
          <>
            <SizedBox height={1.0} />
            <Button
              fontWeight={700}
              fontSize={1}
              maxWidth={15}
              borderRadius={1.5}
              paddingTopBottom={0.6}
              onClick={(e) => {
                e.preventDefault();
                handleProceedLogin();
              }}
            >
              Log in
            </Button>
            <SizedBox height={1.0} />
            <FormFooterPrompt
              question={"Forgot  Password?"}
              proceed_to={"Click Here"}
              url={"/forgot_password"}
            />
          </>
        ) : (
          <Spinner size={1.5} />
        )}
      </AuthButtonContainer>
    </FormComponent>
  );
};
