import styled from "styled-components";
import { TableComponent } from "../../../Global/graphical/table/Container";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../../../../static/images/table/Path 123.svg";
import { StudentPROData } from "../../../../config/DataList/studentsPROData/StudentsPROData";
import { GlobalUserProfileContext } from "../../../../App";
import { FINANCE_STATUS_TYPES } from "../../../../config/store/constants";
import { notifyFailure } from "../../../../helper/notifications/notifyFailure";
import { notifyApiErrorMessage } from "../../../../helper/notifications/notifyApiErrorMessage";
import { axiosServerInstance } from "../../../../config/api/axios";
import { formatQueryDictToQueryParams } from "../../../../helper/formatters/formatDictToParams";
import { Spinner } from "../../../Global/graphical/Spinner";
import { SelectBtn } from "../../../Global/SelectBtn";
import { SizedBox } from "../../../Global/SizedBox";
import FilterByBatch from "../../../Global/filters/FilterByBatch";

const StyledComponent = styled.div`
  .tableOuter {
    max-height: 560px;
    ${"" /* overflow: scroll; */}
    overflow: auto;
    ${"" /* overflow-x: auto; */}
  }
  .infoWrapper {
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .searchActions {
    display: flex;
    gap: 2rem;
    align-items: center;
    @media (max-width: 1380px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .helperText {
      flex: auto;
    }

    .selectOuter {
      select {
        min-width: 140px;
        max-width: 170px;
        width: 100%;
        padding: 0.6rem;
        text-indent: 10px;
        background: var(--custom-btn-bg);
        border-radius: 25px;
        outline: none;
        background-image: none;
        overflow: hidden;
        color: var(--custom-white);

        option {
          color: var(--custom-btn-bg);
          border-radius: 25px;
          width: 32%;
          background: var(--custom-white);
        }
      }
    }
  }

  .searchOuter {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border: 1px solid #ddd;
    border-radius: 2.5rem;
    background-color: #fff;
    height: 3rem;
    width: 100%;
    max-width: 33.9375rem;
    align-items: center;
    padding: 0 1rem;

    input {
      border: none;
      outline: none;
      width: 100%;
      background: transparent;
    }
  }
`;
export const FinanceListComponents = () => {
  const [tableBodyData, setTableBodyData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const { setTargetPage } = useContext(GlobalUserProfileContext);
  const [batchFilter, setBatchFilter] = useState({ batch: "" });
  const navigate = useNavigate();
  const endpointQueryParam = useRef({});
  const headerData = [
    "ID",
    "BSS ID",
    "Name",
    "PHONE NO.",
    "REGION",
    "City",
    "Campus",
    "ADDMISSION FEE",
    "STATUS",
    "",
  ];

  const onClickEdit = async (id) => {
    setShowSpinner(true);
    // setTargetPage("STATUS DETAILS");
    navigate(`/pro/students/${id}/`, { replace: true });
    setShowSpinner(false);
  };
  const handleSelectChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleOnClickAddStudent = (id) => {
    setShowSpinner(true);

    navigate(`/addStudent`, { replace: true });
    setShowSpinner(false);
  };

  const handleSearchInputChange = (e) => {
    let query = e.target.value;
    setSearchParam(e.target.value);
    handleSearch(query);
  };

  const handleSearch = (query) => {
    if (query) {
      endpointQueryParam.current = {
        ...endpointQueryParam,
        search: query,
      };
    } else {
      const tempData = {
        ...endpointQueryParam.current,
      };
      delete tempData["search"];
      endpointQueryParam.current = tempData;
    }
    fetchAndParseDataFromAPI();
  };

  const [data, setData] = useState([]);
  const fetchAndParseDataFromAPI = async () => {
    const endpoint = `/pro/students?${formatQueryDictToQueryParams(
      endpointQueryParam.current
    )}&account_data__batch=${batchFilter.batch}`;
    setShowSpinner(true);
    axiosServerInstance()
      .get(endpoint)
      .then((response) => {
        setData(
          response.data?.results?.filter(
            (info) =>
              info?.account_data?.admission_status?.admission_status ===
                FINANCE_STATUS_TYPES.RESERVED ||
              info?.account_data?.admission_status?.admission_status ===
                FINANCE_STATUS_TYPES.CONFIRMED
          )
        );
        setShowSpinner(false);
      })
      .catch((err) => {
        notifyFailure("Error in fetching student's finances");
        setShowSpinner(false);
        notifyApiErrorMessage(err);
      });
  };

  useEffect(() => {
    if (selectedClient) {
      endpointQueryParam.current =
        selectedClient === "all"
          ? {}
          : {
              account_data__admission_status__admission_status: selectedClient,
            };
      fetchAndParseDataFromAPI();
    }
  }, [selectedClient]);

  useEffect(() => {
    fetchAndParseDataFromAPI();
  }, [batchFilter]);

  useEffect(() => {
    if (data) {
      setTableBodyData(
        data.map((item, index) => {
          return {
            id: item.id,
            data: [
              {
                type: "text",
                value: index + 1,
              },
              {
                type: "text",
                value: item?.id,
              },
              {
                type: "text",
                value: item?.full_name,
              },
              {
                type: "text",
                value: item?.phone_number,
              },

              {
                type: "text",
                value: item?.city_campus[0]?.region_name,
              },

              {
                type: "text",
                value: item?.city_campus[0]?.city_name,
              },
              {
                type: "text",
                value: item?.city_campus[0]?.campus_name,
              },
              {
                type: "badge-custom",
                value: "",
                // No ADMISSION FEES from API yet!
              },
              {
                type:
                  item?.account_data?.admission_status?.admission_status &&
                  "badge-custom",
                value: item?.account_data?.admission_status?.admission_status,
              },
              {
                type: "edit",
                value: onClickEdit,
              },
            ],
          };
        })
      );
    }
  }, [data]);

  return (
    <StyledComponent>
      <div className='searchActions'>
        {/* <SelectBtn
                    setSelectedClient={setSelectedClient}
                    selectedClient={selectedClient}
                    options={FINANCE_STATUS_TYPES}
                /> */}
        <div style={{ display: "flex", gap: "1rem" }}>
          <div className='selectOuter'>
            <FilterByBatch setBatchFilter={setBatchFilter} />
          </div>
          <div className='selectOuter'>
            <select
              value={selectedClient}
              onChange={(e) => {
                setSelectedClient(e.target.value);
              }}
            >
              <option value='all'>All</option>
              {Object.values(FINANCE_STATUS_TYPES).map((status) => (
                <option selected={status === ""} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedClient === "Reserved" && (
          <p className='helperText'>
            Students whose challan form has been issued for 7 days
          </p>
        )}

        <div className='searchOuter'>
          <input
            type='search'
            placeholder='Search student by name, city...'
            value={searchParam}
            onChange={handleSearchInputChange}
          />
          <img
            src={search}
            alt='search icon'
            className={"searchIcon"}
            onClick={(searchParam) => handleSearch}
          />
        </div>
      </div>
      <SizedBox height={1} />
      {!showSpinner ? (
        <TableComponent
          headerData={headerData}
          bodyData={tableBodyData}
          actionEdit={{
            edit: onClickEdit,
          }}
        />
      ) : (
        <div className='infoWrapper'>
          <Spinner />
        </div>
      )}
    </StyledComponent>
  );
};
