import { AuthMainContainer } from "../components/AuthMainContainer";
import { CreatePasswordForm } from "./Form";

const CreatePasswordContainer = () => {
  return (
    <AuthMainContainer mode={"create_password"}>
      <CreatePasswordForm />
    </AuthMainContainer>
  );
};
export default CreatePasswordContainer;
