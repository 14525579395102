import { SizedBox } from "../../Global/SizedBox";
import { FormComponent } from "../components/FormElement";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { GlobalUserProfileContext } from "../../../App";
import { axiosServerInstance } from "../../../config/api/axios";
import { notifySuccess } from "../../../helper/notifications/notifySuccess";
import { notifyApiErrorMessage } from "../../../helper/notifications/notifyApiErrorMessage";
import { Spinner } from "../../Global/graphical/Spinner";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "../../../config/store/actions";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const EmailVerificationForm = () => {
  const [processingStates, setProcessingStates] = useState({});
  const [
    resendingVerificationMailTimeRemaining,
    setResendingVerificationMailTimeRemaining,
  ] = useState(0);
  const { profile, isFetchingProfile } = useContext(GlobalUserProfileContext);
  const verificationMailSent = useState(
    profile?.has_pending_email_verification_request
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [verToken, setVerToken] = useState("");
  const [renderContent, setRenderContent] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (resendingVerificationMailTimeRemaining > 0) {
      setTimeout(
        () =>
          setResendingVerificationMailTimeRemaining(
            resendingVerificationMailTimeRemaining - 1
          ),
        1000
      );
    }
  }, [resendingVerificationMailTimeRemaining]);

  const handleVerifyProceed = (token) => {
    const endpoint = "/auth/finalize_verify_email/";
    const data = { token };
    setProcessingStates({
      ...processingStates,
      verifyingEmail: true,
    });
    axiosServerInstance(profile?.token?.access)
      .post(endpoint, data)
      .then((response) => {
        setProcessingStates({
          ...processingStates,
          verifyingEmail: false,
        });
        dispatch(fetchUserProfile);
        setTimeout(() => {
          notifySuccess("Email verification successful");
          navigate("/", { replace: true });
        }, 2000);
      })
      .catch((err) => {
        notifyApiErrorMessage(err);
        setProcessingStates({
          ...processingStates,
          verifyingEmail: false,
        });
        setRenderContent(true);
      });
  };

  useEffect(() => {
    if (verToken) {
      handleVerifyProceed(verToken);
    }
  }, [verToken]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("token")) {
      setVerToken(query.get("token"));
    } else {
      setRenderContent(true);
    }
  }, []);

  const handleResendVerificationMail = () => {
    const endpoint = "/auth/initiate_verify_email/";
    setProcessingStates({
      ...processingStates,
      resendingVerificationMail: true,
    });
    axiosServerInstance(profile?.token?.access)
      .post(endpoint)
      .then((response) => {
        setProcessingStates({
          ...processingStates,
          resendingVerificationMail: false,
        });
        dispatch(fetchUserProfile);
        setResendingVerificationMailTimeRemaining(10);
      })
      .catch((err) => {
        notifyApiErrorMessage(err);
        setProcessingStates({
          ...processingStates,
          resendingVerificationMail: false,
        });
      });
  };
  return renderContent ? (
    <FormComponent>
      <div>
        <div className='infoWrapper'>
          {processingStates?.verifyingEmail ? (
            <Spinner size={2} />
          ) : !profile && !isFetchingProfile ? (
            <Navigate to={"/login"} replace={true} />
          ) : (
            <>
              {verificationMailSent && (
                <span className='info'>
                  A verification link has been sent to {profile?.email}, kindly
                  click on it to verify your account email address
                </span>
              )}
              {processingStates?.resendingVerificationMail ? (
                <Spinner size={1} />
              ) : verificationMailSent ? (
                <span>
                  <span className='info'>
                    Did not receive verification mail,{" "}
                  </span>
                  {resendingVerificationMailTimeRemaining ? (
                    <span>
                      Try again in {resendingVerificationMailTimeRemaining}{" "}
                      seconds
                    </span>
                  ) : (
                    <span>
                      click{" "}
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault();
                          handleResendVerificationMail();
                        }}
                      >
                        here
                      </a>{" "}
                      to send again.
                    </span>
                  )}
                </span>
              ) : (
                <span>
                  <span>
                    Click{" "}
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        handleResendVerificationMail();
                      }}
                    >
                      here
                    </a>{" "}
                    to verify your account email address.
                  </span>
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </FormComponent>
  ) : (
    <StyledWrapper>
      <Spinner size={1.5} />
    </StyledWrapper>
  );
};
